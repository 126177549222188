import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import GoogleLogin from './GoogleLogin';
import ExcelUpload from './components/ExcelUpload';
import SharePlace from './components/SharePlace';
import AppVision from './components/AppVision';
import FeedBack from './components/FeedBack';
import ScheduleMain from './components/ScheduleMain';
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import LandingPage from './LandingPage';
import LanguageToggle from './components/LanguageToggle';
import './global-styles.css';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to="/login" />;
};

const AppLayout = ({ children }) => (
  <div className="app-layout">
    {children}
    <LanguageToggle />
  </div>
);

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <LanguageProvider>
          <AppLayout>
            <Routes>
              {/* 공개 라우트 */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<GoogleLogin />} />
              <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/Terms" element={<Terms />} />
              <Route path="/AppVision" element={<AppVision />} />
              
              {/* 보호된 라우트 */}
              <Route path="/ExcelUpload" element={
                <ProtectedRoute>
                  <ExcelUpload />
                </ProtectedRoute>
              } />
              <Route path="/SharePlace" element={
                <ProtectedRoute>
                  <SharePlace />
                </ProtectedRoute>
              } />
              <Route path="/FeedBack" element={
                <ProtectedRoute>
                  <FeedBack />
                </ProtectedRoute>
              } />
              <Route path="/ScheduleMain" element={
                <ProtectedRoute>
                  <ScheduleMain />
                </ProtectedRoute>
              } />
            
              {/* 404 리다이렉트 */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </AppLayout>
        </LanguageProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;