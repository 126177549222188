import React from 'react';
import { Box, CssBaseline, Typography, Grid, Paper, Fade } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NavigationBar from './NavigationBar';
import './AppVision.css';
import { 
  Schedule, Restaurant, ShoppingCart, Spa, 
  Accessibility, Info, Favorite, Group, TrendingUp 
} from '@mui/icons-material';

const theme = createTheme();

const FeatureCard = ({ title, description, icon }) => (
  <Fade in={true} timeout={1000}>
    <Paper elevation={3} className="feature-card">
      <Box className="icon-wrapper">{icon}</Box>
      <Typography variant="h6" component="h3" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2">{description}</Typography>
    </Paper>
  </Fade>
);

const AppVision = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <NavigationBar />
        <CssBaseline />
        <div className="app-vision-container gradient-background">
          <div className="app-vision-content">
            <Typography variant="h3" component="h1" gutterBottom className="app-vision-title">
              CREWCREW 목표
            </Typography>
            <Typography variant="h5" className="app-vision-subtitle">
              여러분의 삶을 보다 풍요롭게 만드는 일상을 디자인합니다.
            </Typography>

            <Grid container spacing={3} className="features-grid">
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard 
                  title="스마트 스케줄 관리" 
                  description="자동 스케줄 업로드, 스케줄 파싱, 캘린더 연동을 통해 일정을 효율적으로 관리합니다."
                  icon={<Schedule fontSize="large" />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard 
                  title="맛집 정보 공유" 
                  description="지역별 인기 맛집 정보를 제공하고, 사용자 리뷰를 통해 신뢰할 수 있는 맛집을 추천합니다."
                  icon={<Restaurant fontSize="large" />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard 
                  title="쇼핑 정보" 
                  description="최신 쇼핑 트렌드와 특별 할인 정보를 제공하여 사용자의 쇼핑 경험을 향상시킵니다."
                  icon={<ShoppingCart fontSize="large" />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard 
                  title="힐링 & 마사지 정보" 
                  description="여행지나 거주지 인근의 힐링 스팟과 마사지샵 정보를 제공하여 편안한 휴식을 즐길 수 있게 합니다."
                  icon={<Spa fontSize="large" />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard 
                  title="편리함" 
                  description="다양한 일정을 하나의 앱에서 관리할 수 있어 시간과 노력을 절약할 수 있습니다."
                  icon={<Accessibility fontSize="large" />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard 
                  title="풍부한 정보" 
                  description="여행, 맛집, 쇼핑, 힐링 등 다양한 라이프스타일 정보를 한눈에 확인할 수 있습니다."
                  icon={<Info fontSize="large" />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard 
                  title="맞춤형 추천" 
                  description="개인의 선호도와 활동 내역을 기반으로 한 맞춤형 추천으로 더욱 만족스러운 경험을 제공합니다."
                  icon={<Favorite fontSize="large" />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FeatureCard 
                  title="커뮤니티" 
                  description="사용자들 간의 정보 공유와 리뷰를 통해 더욱 풍성한 정보를 얻을 수 있습니다."
                  icon={<Group fontSize="large" />}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" component="h2" gutterBottom className="app-vision-subtitle">
              미래 전망
            </Typography>
            <Paper elevation={3} className="future-vision">
              <TrendingUp fontSize="large" />
              <Typography variant="body1" className="app-vision-text">
                우리의 어플리케이션은 단순한 스케줄 관리 도구를 넘어, 사용자의 일상을 풍요롭게 만드는 라이프스타일 플랫폼으로 자리잡을 것입니다. 사용자가 필요로 하는 모든 정보를 한 곳에서 제공하여, 더욱 편리하고 만족스러운 일상을 디자인하는 것이 우리의 목표입니다.
              </Typography>
            </Paper>
          </div>
        </div>
      </Box>
    </ThemeProvider>
  );
};

export default AppVision;